<template>
    <CRow>
        <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars" />
        <CCol col="12" xl="12">
        <CCard>
            <CCardHeader class="text-center bg-dark text-white">
                <b>{{$t('label.locationList')}}</b>
            </CCardHeader>
            <CCardBody>
                <modalPais />
                <modalEstado />
                <modalMunicipio />
                <modalParroquia />
                <CRow>
                    <CCol sm="12">
                        <CustomTabs @update:activeTab="handleTab" >
                            <CustomTab >
                                <template #title>
                                    <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Country.svg" alt="Card image cap">
                                    {{$t('label.country')}}                
                                </template>
                                <appPais :Tab="Tab" :loading="apiStateLoading"></appPais>
                            </CustomTab>
                            <CustomTab :title="$t('label.state')">
                                <appEstado :Tab="Tab" :loading="apiStateLoading" :Mounted="Mounted"></appEstado>
                            </CustomTab>
                            <CustomTab >
                                <template #title>
                                    <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Municipality.svg" alt="Card image cap">
                                    {{$t('label.municipality')}}                
                                </template>
                                <appMunicipio :Tab="Tab" :loading="apiStateLoading"></appMunicipio>
                            </CustomTab>
                            <CustomTab >
                                <template #title>
                                    <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Parish.svg" alt="Card image cap">
                                    {{$t('label.parish')}}                
                                </template>
                                <appParroquia :Tab="Tab" :loading="apiStateLoading"></appParroquia>
                            </CustomTab>
                        </CustomTabs>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
        </CCol>
    </CRow>
</template>

<style>
    .pcss3t > ul, .pcss3t > ul > li {
        height: 600px;
    }
</style>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';

    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';

    import appPais from './app-pais';
    import appEstado from './app-estado';
    import appMunicipio from './app-municipio';
    import appParroquia from './app-parroquia';
    
    import modalPais from './modals/app-modal-pais';
    import modalEstado from './modals/app-modal-estado';
    import modalMunicipio from './modals/app-modal-municipio';
    import modalParroquia from './modals/app-modal-parroquia';

    //data
    function data() {
        return {
           activeTab: 0,
           Tab: 0,
           Mounted: true,
        }
    }

    //Methods
    async function getCompany() {
        let listado=[];
        await this.$http.get("Company-by-id", { CompanyId: this.user.CompanyId })
            .then((response) => {
            listado = [...response.data.data];
            this.$store.state.ubicacion.filtroPais = listado[0].CountryId ? listado[0].CountryId : '';
            //this.$store.commit('ubicacion/mutationfiltroPais', this.$store.state.ubicacion.filtroPais);
            })
            .catch((err) => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: 'error',
            });
        });
    }
    async function handleTab(tab) {
        if (tab!=0&&this.Mounted) {
            await this.getCompany();
            this.Mounted = false;
        }
       this.Tab = tab; 
       this.$store.state.ubicacion.tabIndex = tab;
    }
    function isActiveTab(current) {
        return this.$store.state.ubicacion.tabIndex == current;
    }
    //computed
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    //created
    function created() {
        this.$store.dispatch('ubicacion/getPaislist');
    }

    //watch
    function apiState(newQuestion,OldQuestion){
        if(newQuestion === ENUM.ERROR){
            if(this.apiStateForm !== ENUM.ERROR){
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? this.$t('label.unexpectedError') :  this.messageError)
                });
                this.$store.state.ubicacion.messageError = '';
            }
        }
    }
    function apiStateForm(newQuestion,OldQuestion){
        switch (newQuestion) {
            case ENUM.ERROR:
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? this.$t('label.unexpectedError') :  this.messageError)
                });
                this.$store.state.ubicacion.messageError = '';
            
                break;
            case ENUM.LOADED:
                this.$notify({
                    group: 'container',
                    type: 'success',
                    ignoreDuplicates:false,
                    title: '¡Éxito!',
                    text: (this.messageError === '' ? this.$t('label.completedSuccessfully') :  this.messageError)
                });
                this.$store.state.ubicacion.messageError = '';
                
                break;
            default:
                break;
        }
    }

    //Fields
    export default {
        name: 'app-ubicacion',
        data,
        created,
        methods: {
            handleTab,
            isActiveTab,
            getCompany,
        },
        computed:{
            apiStateLoading,
            apiStateFormLoading,
            ...mapState({
                apiState: state => state.ubicacion.apiState,
                apiStateForm: state => state.ubicacion.apiStateForm,
                messageError: state => state.ubicacion.messageError,
                tabIndex: state => state.ubicacion.tabIndex,
                user: state => state.auth.user
            })
        },
        components: {
            CustomTabs,
            CustomTab,
            appPais,
            appEstado,
            appMunicipio,
            appParroquia,
            modalPais,
            modalEstado,
            modalParroquia,
            modalMunicipio,
            
        },
        watch:{
            apiState,
            apiStateForm
        }
    }
</script>
<style lang="css">
    .iconTab{
        max-width: 100%;
        height: auto;
        border-radius: 0.25rem !important;
        margin-left: 1.5rem !important;
        margin-top: -0.4rem !important;
    } 
</style>